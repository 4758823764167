<template>
  <div>
    <slot :row="{}"></slot>
  </div>
</template>

<script>
export default {
  props: {
    // 文本
    label: String,
    // value字段
    prop: String,
    //宽度
    width: String,
    //类型
    type: {
      type: String,
      default: "text",
    },
    align: {
      type: String,
      default: "left",
    },
  },
}
</script>

<style></style>
